import React from 'react';
import cn from 'classnames';
import { HTMLtoJSX, removeTagsFromString } from '../../../../../../../helpers';
import styles from './ValueProposition.module.scss';
import { HeadingSizeTags, TitleSizeType } from '../../../../../../../types';

export enum ValuePropositionSizes {
  normal = 'normal',
  smaller = 'smaller',
}

export interface ValuePropositionProps {
  text: React.ReactNode;
  size: keyof typeof ValuePropositionSizes;
  tag: TitleSizeType;
}

export function ValueProposition(props: ValuePropositionProps) {
  const { text, size, tag } = props;
  const HeadingTag = HeadingSizeTags[tag];

  return (
    <HeadingTag className={cn(styles.heading, styles[size])}>
      {HTMLtoJSX(removeTagsFromString(text, ['br']))}
    </HeadingTag>
  );
}

ValueProposition.defaultProps = {
  size: ValuePropositionSizes.normal,
  tag: 'h2',
};
